import '../styles/faq.scss';

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import DownArrow from '../images/DownArrow.jpeg';
import UpArrow from '../images/UpArrow.jpeg';

function FaqPage() {

  const onClick_section = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick_section ' + event.target.name );
    if ( !showSections[index] ) {
      setShowSections( { ...showSections, [index]: true } );
    }
    else {
      setShowSections( { ...showSections, [index]: false } );
    }
  };

  const onClick = ( event, index ) => {
    console.log( 'Event target name >>>>>>>> onClick ' + event.target.name, showResults[index] );
    if ( !showResults[index] ) {
      setShowResults( { ...showResults, [index]: true } );
    }
    else {
      setShowResults( { ...showResults, [index]: false } );
    }
  };

  const [ showSections, setShowSections ] = React.useState( { 1: true } );
  const [ showResults, setShowResults ] = React.useState( {} );

  return (
    <Layout header={'menu'}>
      <SEO
        title="Get answers related to Life &amp; Home Insurance Queries "
        description="Our FAQ section will help you get answers to the most frequently asked questions, related to Life and Home Insurance. Visit now!"
        canonicalUrl='https://www.getmybubble.io/faq'
        robots={ { 1:'index', 2: 'follow' } }
      />

      <div id="faq" className="wrapper">
        <div className="flexColumn carrierCommonPage">
          <div className="company-info-container container-fluid">
            <div className="row">
              <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                  <h1 className="carrier-plan-heading section70" style={{ lineHeight: '1.55', textAlign: 'center' }}>
                                    Frequently Asked Questions
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="container faq-margin-bottom common-container-space">
            <div className="row">
              <div className="col-lg-12">
                {/*About Home Insurance*/}

                <div className="row">
                  <div className="col-lg-12">
                    <div className="faqFlex bg-white-border">
                      <div className="faqLeftContainer faq-left-container">
                        <div className="col-sm-12" onClick={( e ) => onClick_section( e, 1 )}>
                          <div className="align-faq-que position-relative">
                            <div className="faq-section-headings">
                                                                Policy and Claims
                            </div>

                            <img
                              src={showSections[1] ? UpArrow : DownArrow}
                              className="faq-dd m-0" name="1" alt="Bubble Life Insurance"/></div>
                        </div>

                        <div className="col-12 both-space-padding">
                          <div className="row">
                            {showSections[1] && <>
                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 3 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What can I do to prevent
                                                                                                filing a home insurance claim? </div><img src={showResults[3] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="13" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[3] && `Lots of things! Getting a
                                                                                            home security system, setting up alarms to turn your sprinklers on or
                                                                                            off, setting up a motion detection sensor, clearing dead leaves, grass,
                                                                                            tree branches from the home periphery, and something as simple as
                                                                                            closing your doors and windows securely at night may help prevent you
                                                                                            making an insurance claim.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 4 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> If my home has been damaged
                                                                                                by natural perils, can I start repairs before my claim goes through?
                                          </div><img src={showResults[4] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0" name="21"
                                            alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[4] && `We understand that you may
                                                                                            want to fix your home as soon as you see the damage. But you will mask a
                                                                                            great deal of the damage and the adjuster wouldn't be able to make a
                                                                                            fair assessment of the loss.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 5 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> When should I file for a home
                                                                                                insurance claim? </div><img src={showResults[5] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="24" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[5] && `If a small item like a
                                                                                            child's bicycle is stolen or an old piece of furniture is broken, with a
                                                                                            claim you may risk a higher premium. The value of such items is
                                                                                            insignificant compared to the claim you would file for.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 6 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> What if my home insurance
                                                                                                claim is refused? </div><img src={showResults[6] ? UpArrow : DownArrow}
                                            className="faq-dd faq-dd-width m-0" name="42" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[6] && `Reasons why your claim
                                                                                            would have been refused: You have not disclosed information during
                                                                                            application or renewal, if you have been fraudulent, if your policy
                                                                                            doesn't cover the loss/damage, and if your policy has expired. If none
                                                                                            of these reasons apply to you, please contact your person of contact.
                                                                                        `}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-12">
                                <div className="faqSubContainer">
                                  <div className="faqFlex faq-container-align">
                                    <div className="faqLeftContainer row">
                                      <div className="col-sm-12 col-12" onClick={( e ) => onClick( e, 7 )}>
                                        <div className="align-faq-que-box position-relative">
                                          <div className="carrier-faq carrier-faq-width"> Can I make changes to my
                                                                                                policy? </div><img src={showResults[7] ? UpArrow : DownArrow} className="faq-dd faq-dd-width m-0"
                                            name="43" alt="Bubble Life Insurance" />
                                        </div>

                                        <p className="pt-lg-0 pt-2 carrier-faq faq-para-text">{showResults[7] && `Of course, you can make
                                                                                            any changes or adjustments. Buy exactly what you need right now and add
                                                                                            coverage or make any changes at a later time as you need.`}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*End of Home Insurance */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default FaqPage;
